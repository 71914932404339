<template>
    <div>
        <h2>Quotation</h2>
    </div>
</template>
<script>
export default {

}
</script>
<style></style>