<template>
    <div class="card-body">
        <form class="form form-horizontal" @submit.prevent="storeContact">

            <div class="row">
                <div class="col-sm-6 col-lg-6 col-12 offset-3">
                    <div class="row">

                        <div class="col-12">
                            <div class="mb-1 row">
                                <v-select style="padding:0" placeholder="Select Product or Service"
                                    v-model="formData.service_id" :options="[]" label="name" :reduce="name => name.id" />
                            </div>
                        </div>

                        <div class="col-12 mr-1">
                            <div class="mb-1 row">
                                <textarea class="form-control" v-model="formData.service_details" placeholder="Product or Service Details" rows="1"></textarea>
                                <!-- <input type="text" class="form-control" v-model="formData.service_id"
                                    placeholder="Product or Service Details" /> -->
                            </div>
                        </div>

                        <div class="col-6 _col_width">
                            <div class="mb-1 row">
                                <input type="text" class="form-control" v-model="formData.expected_revenue"
                                    placeholder="Expected revenue" />
                            </div>
                        </div>

                        <!-- <div class="col-2"></div> -->

                        <div class="col-6 _col_width _probability_inp">
                            <div class="mb-1 row">
                                <input type="number" class="form-control" v-model="formData.probability"
                                    placeholder="Probability(%)" />
                            </div>
                        </div>

                        <div class="col-6 _col_width">
                            <div class="mb-1 row">
                                <label class="_cl_date_lb">Estimated Closing Date</label>
                                <input type="date" class="form-control" v-model="formData.expected_closing"
                                    placeholder="Expected Closing Date" />
                            </div>
                        </div>

                        <div class="col-6 _col_width _probability_inp">
                            <div class="mb-1 row _priority_div">
                                <input type="text" class="form-control" v-model="formData.priority"
                                    placeholder="Priority" />
                            </div>
                        </div>

                        <div class="col-sm-6 offset-sm-3">
                            <button type="submit" class="btn btn-primary me-1">Submit</button>
                            <button type="reset" class="btn btn-outline-secondary">Reset</button>
                        </div>

                    </div>
                </div>

                <!-- <div class="col-sm-6 col-lg-6 col-12 _probability">
                    <div class="text-center">
                        <p>Probability</p>
                        <p>88%</p>
                    </div>
                </div> -->
            </div>

        </form>
    </div>
</template>

<script setup>

import { reactive, ref } from "vue";

const formData = reactive({
    service_id: null,
    service_details: '',
    expected_revenue: '',
    probability: '',
    expected_closing: '',
    priority: '',
})

const storeContact = () => {

}
</script>

<style scoped>
._probability {
    display: flex;
    align-items: center;
    justify-content: center;
}

._col_width{
    width: 49% !important;
}

._probability_inp{
    margin-left: 2% !important;
}

._cl_date_lb{
    padding-left: 0 !important;
}

._priority_div{
    margin-top: 10%;
}

</style>
