<template>
    <div class="card" style="min-height: calc(100vh - 180px)">
        <div class="bg-blue-light p-1">
            <TitleButton btnTitle="List" :showBtn="true" :showAddNew="false" title="Create New Deal"
                @onClickCloseButton="navigateToListPage" />

            <div class="_deal_name">
                <div class="col-12 _deal_title">
                    <div class="mb-1 row">
                        <textarea class="form-control" placeholder="Deal Title" rows="1"></textarea>
                            <!-- <input type="textarea" :rows="4" class="form-control" v-model="formData.expected_closing"
                                placeholder="Deal Title" /> -->
                        </div>
                    </div>
                <!-- <p class="h3 m-0" >Arrangement Agreement Deal</p> -->
            </div>

            <div class="row gy-1">
                <div class="col-md-4">
                    <v-select placeholder="Select Contact" v-model="formData.contact_id" :options="contacts" label="name"
                        :reduce="name => name.id" />
                </div>
                <div class="col-md-4">
                    <v-select placeholder="Select Deal Source" v-model="formData.source_id" :options="sources"
                        label="name" :reduce="name => name.id" />
                </div>
                <div class="col-md-4">
                    <v-select placeholder="Select Deal Owner" v-model="formData.owner_id" :options="owners"
                        label="name" :reduce="name => name.id" />
                </div>

                <div class="col-md-4">
                    <v-select placeholder="Deal Assing to" v-model="formData.assing_id" :options="assigns"
                        label="name" :reduce="name => name.id" />
                </div>

                <div class="col-md-4">
                    <v-select placeholder="Campaign" v-model="formData.campaign_id" :options="campaigns"
                        label="name" :reduce="name => name.id" />
                </div>

            </div>
        </div>

         <div class="px-2">
            <ul class="nav nav-tabs" role="tablist">

                <!-- <li class="nav-item">
                    <a href="#" class="nav-link text-primary _tab_title">Deal Information</a>
                </li> -->

                <li v-for="tab in tabs" :key="tab.id" class="nav-item">
                    <a
                        @click="setActiveTab(tab)"
                        :class="tab.id === activeTabId && 'active'"
                        class="nav-link" data-bs-toggle="tab" 
                        aria-controls="home" role="tab" aria-selected="true">
                        {{tab.title}}
                    </a>
                </li>
            </ul>
        </div>

        <div class="px-3 _component">
            <component :is="activeTab"/>
        </div>

    </div>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TitleButton from '@/components/atom/TitleButton';
import DealInfo from './tab/DealInfo.vue'
import Quotation from './tab/Quotation.vue'
import Meeting from './tab/Meeting.vue'
import Task from './tab/Task.vue'
const router = useRouter()
const route = useRoute()

const tabs = [
    { id: 'deal-info', title: 'Deal Info', component: DealInfo },
    { id: 'quotation', title: 'Quotation', component: Quotation },
    { id: 'meeting', title: 'Meeting', component: Meeting },
    { id: 'task', title: 'Task', component: Task },
]
const activeTab = ref(DealInfo);
const activeTabId = ref('deal-info')
const setActiveTab = tab => {
    activeTab.value = tab.component;
    activeTabId.value = tab.id;
};

let formData = reactive({
    contact_id: null,
    source_id: null,
    owner_id: null,
    assing_id: null,
    campaign_id: null,
    negotiation_id: null,
    has_item_detail: true,
 
})
const contacts = reactive([
    {id: 1, name: 'Shamol Sarker'},
    {id: 2, name: 'Apple Corporation'},
]);
const sources = reactive([
    { id: 1, name: 'Facebook' },
    { id: 2, name: 'Linkeding' },
    { id: 3, name: 'Linkeding' },
    { id: 4, name: 'Campaign' },
]);
const owners = reactive([
    { id: 1, name: 'Shamol Sarker' },
    { id: 2, name: 'User 2' },
    { id: 3, name: 'User 3' },
    { id: 4, name: 'User 4' },
]);
const assigns = reactive([
    { id: 1, name: 'Shamol Sarker' },
    { id: 2, name: 'User 2' },
    { id: 3, name: 'User 3' },
    { id: 4, name: 'User 4' },
]);

const campaigns = reactive([
    { id: 1, name: 'Campaign 1' },
    { id: 2, name: 'Campaign 2' },
]);

const negotiations = reactive([
    { id: 1, name: 'Quatation' },
    { id: 2, name: 'Negotiation' },
    { id: 3, name: 'Win' },
]);



function navigateToListPage() {
    router.push({ name: 'deal-list', params: route.params, query: route.query })
}


</script>

<style scoped>

._component{
    min-height: 300px;
}
._deal_name{
    padding: 7px;
    padding-left: 0px;
    /* border-bottom: 1px solid gray; */
    border-top: 1px solid gray;
}

._deal_title{
    padding-left: 15px;
    padding-right: 15px;
}

._tab_title{
    font-size: 22px;
}

</style>
